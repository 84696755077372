<template>
  <div>
    <van-nav-bar
      title="欢迎使用中控大师「合伙人」系统"
    />
    <van-nav-bar
      title="登录"
    />
    <van-form>
      <van-field
        v-model.trim="phone"
        name="手机号码"
        label="手机号码"
        placeholder="手机号码"
      />
      <van-field
        v-model.trim="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
      />
      <div style="margin: 16px">
        <van-button round block type="info" @click="doLogin">登录</van-button>
      </div>

      <div style="margin: 16px">
        <van-row>
          <van-col span="24"><router-link to="/member/register">没有账号？去注册</router-link></van-col
          >
        </van-row>
      </div>
    </van-form>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      token: "",
      phone: "",
      password: "",
    };
  },
  components: {
    CopyRight,
  },

  methods: {
    doLogin() {
      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return;
      }
      if (!this.phone.match(/^1[3-9]\d{9}$/)) {
        this.$toast.fail("手机号码格式错误");
        return;
      }

      if (!this.password) {
        this.$toast.fail("请填写密码");
        return;
      }

      if (this.password.length < 6) {
        this.$toast.fail("密码必须大于等于6位");
        return;
      }

      let postData = this.$qs.stringify({
            phone:this.phone,
            password:this.password,
        });

      axios
        .post("api/member/login", postData)
        .then((res) => {
          if(res.data.code == 0){
            window.sessionStorage.setItem("token", res.data.data.token);
            this.$toast("登录成功");
            this.$router.push('/')
          }else{
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log("login err-->" + err);
          this.$toast.fail("登录失败");
        });
    },
  },
};
</script>
